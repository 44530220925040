import React from 'react';
import 'tippy.js/dist/tippy.css';
import Image from "next/image";

const Item = (props) => {
  const objectID = props.hit?.objectID;
  const website = props.hit?.website;
  const name = props.hit?.name;
  const twitter = props.hit?.twitter;
  const start_date = props.hit?.start_date;
  const end_date = props.hit?.end_date;
  const location = props.hit?.location;
  const image = props.hit?.image;
  return (
    <>
      <a href={website} key={objectID}
         className="dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 rounded-2.5xl mb-2 relative flex items-center border bg-white p-3 transition-shadow hover:shadow-lg">
        <figure className="mr-5 self-start">
          {image!=="" &&
          <Image
            src={'https://backend.softwaretalks.io/images/conferences/' + image}
            alt={name}
            height={60}
            width={60}
            objectFit="cover"
            className="rounded-2lg"
            loading="lazy"
          />
          }
        </figure>

        <div className=" ml-1 ">
          <h3 className="font-display text-jacarta-700 mb-1 text-base font-semibold dark:text-jacarta-200 text-left">{name}</h3>
          <span className="dark:text-jacarta-200 text-jacarta-500 mb-2 block text-sm text-left">
              From {start_date} to {end_date}. {location}
            </span>
          <span className="text-jacarta-300 block text-xs text-left">{website} - {twitter}</span>
        </div>

        <div className="dark:border-jacarta-600 border-jacarta-100 ml-auto rounded-full border p-3">
          {/*<svg className="icon fill-jacarta-700 dark:fill-white h-6 w-6">*/}
          {/*  <use xlinkHref={`/icons.svg#icon-${category}`}></use>*/}
          {/*</svg>*/}
        </div>
      </a>
    </>
  );
};

export default Item;
