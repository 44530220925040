import Head from "next/head";

const Meta = ({title, keyword, desc}) => {
  return (
    <div>
      <Head>
        <title>{title}</title>
        <link rel="icon" href="/favicon.png"/>
        <meta name="description" content={desc}/>
        <meta name="keyword" content={keyword}/>
      </Head>
    </div>
  );
};

Meta.defaultProps = {
  title: "Software Talks",
  keyword: "software, tech, conferences",
  desc: "Software and Tech Conferences",
};

export default Meta;
